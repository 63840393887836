import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { jwtDecode } from "jwt-decode";
import axiosInstance from "../lib/axiosInstance";

const isTokenValid = (token) => {
    if (!token) {
        return false;
    }

    try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.inphone) {
            return true;
        }
    } catch (error) {
        console.error('Invalid token:', error);
        return false;
    }
};

export const ProtectedRoute = ({ children }) => {
    const { user } = useAuth();
    if (!isTokenValid(user)) {
        // user is not authorized
        return <Navigate to="/login" />
    }

    axiosInstance.interceptors.request.use(
        (config) => {
            if (user) {
                config.headers['x-auth'] = user;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    return children;
}