import { Stack, Box, Button, Typography, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getBillings, getRecord } from '../../actions/billings'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formatDate } from '../../lib/dateFormat';
import { TableContainer } from '@mui/material';
import { Table } from '@mui/material';
import { TableHead } from '@mui/material';
import { TableRow } from '@mui/material';
import { TableCell } from '@mui/material';
import { TableBody } from '@mui/material';
import { Paper } from '@mui/material';
import { IconButton } from '@mui/material';
import { convertSeconds } from '../../lib/convertSeconds';
import { Headphones } from '@mui/icons-material';
import { SpeakerNotes } from '@mui/icons-material';
import { TimeField } from '@mui/x-date-pickers';
import { extractTime } from '../../lib/extractTime';
import { getTodayDate } from '../../lib/getTodayDate';
import dayjs from 'dayjs';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import { getMainUserList } from '../../actions/users';
import AudioPlayer from '../../components/AudioPlayer';
import { Modal } from '@mui/material';
import NoteAddContainer from '../../components/NoteAddContainer';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { useSocket } from '../../hooks/useSocket';
import { TableFooter } from '@mui/material';
import { TablePagination } from '@mui/material';
import { TablePaginationActions } from '../../components/TablePaginationActions';
import { useIsMobile } from '../../hooks/useMobile'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

const Billingi = () => {
    const socket = useSocket();
    const isMobile = useIsMobile();
    const today = getTodayDate();
    const [dateEnd, setDateEnd] = useState(today);
    const [dateStart, setDateStart] = useState(today);
    const [isRecord, setIsRecord] = useState('');
    const [callType, setCallType] = useState('');
    const [queue, setQueue] = useState('');
    const [timeStart, setTimeStart] = useState('00:00');
    const [timeStop, setTimeStop] = useState('23:59');
    const [user, setUser] = useState('');


    const [data, setData] = useState([]);
    const [users, setUsers] = useState([]);

    // paginacja
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    // paginacja

    const fetchData = async () => {
        const res = await getBillings({
            calltype: callType,
            dateEnd: dateEnd,
            dateStart: dateStart,
            isRecord: isRecord,
            queue: queue,
            timeStart: timeStart,
            timeStop: timeStop,
            user: user
        })
        setData(res)
        const usersRes = await getMainUserList()
        setUsers(usersRes)
    }

    const transformDisposition = (disposition, src) => {
        switch (disposition) {
            case 'NO ANSWER':
                //The channel was not answered. This is the default disposition.
                if (src.length === 2) {
                    return <IconButton sx={{ color: 'info.main' }}>
                        <Tooltip title="Brak odpowiedzi">
                            <KeyboardDoubleArrowUpIcon />
                        </Tooltip>
                    </IconButton>
                }
                else {
                    return <IconButton sx={{ color: 'info.main' }}>
                        <Tooltip title="Brak odpowiedzi">
                            <KeyboardDoubleArrowDownIcon />
                        </Tooltip>
                    </IconButton>
                }
            case 'FAILED':
                //The channel attempted to dial but the call failed.
                if (src.length === 2) {
                    return <IconButton sx={{ color: 'error.main' }}>
                        <Tooltip title="Niepowodzenie">
                            <KeyboardDoubleArrowUpIcon />
                        </Tooltip>
                    </IconButton>
                }
                else {
                    return <IconButton sx={{ color: 'error.main' }}>
                        <Tooltip title="Niepowodzenie">
                            <KeyboardDoubleArrowDownIcon />
                        </Tooltip>
                    </IconButton>
                }
            case 'BUSY':
                //The channel attempted to dial but the remote party was busy.
                if (src.length === 2) {
                    return <IconButton sx={{ color: 'warning.main' }}>
                        <Tooltip title="Zajęty">
                            <KeyboardDoubleArrowUpIcon />
                        </Tooltip>
                    </IconButton>
                }
                else {
                    return <IconButton sx={{ color: 'warning.main' }}>
                        <Tooltip title="Zajęty">
                            <KeyboardDoubleArrowDownIcon />
                        </Tooltip>
                    </IconButton>
                }
            case 'ANSWERED':
                //The channel was answered. The hang up cause will no longer impact the disposition of the CDR.
                if (src.length === 2) {
                    return <IconButton sx={{ color: 'success.main' }}>
                        <Tooltip title="Odpowiedział">
                            <KeyboardDoubleArrowUpIcon />
                        </Tooltip>
                    </IconButton>
                }
                else {
                    return <IconButton sx={{ color: 'success.main' }}>
                        <Tooltip title="Odpowiedział">
                            <KeyboardDoubleArrowDownIcon />
                        </Tooltip>
                    </IconButton>
                }
            case 'CONGESTION':
                //The channel attempted to dial but the remote party was congested.
                return <Typography sx={{ color: 'info.main' }}>Przepełnienie</Typography>
            default:
                return 'Nieznane'
        }
    }
    const convertLastData = (data) => {
        const newData = data.split(',')
        return newData[0]
    }

    // wyswietlenie nagrania
    const [audioSrc, setAudioSrc] = useState(null);
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const [modalData, setModalData] = useState(null)

    const handleGetRecord = async (id, date) => {
        const url = await getRecord(id)
        setAudioSrc(url)
        setModalData(date)
        setOpen(true)
    }
    // wyswietlenie nagrania

    // dodanie notatki
    const [openAddNoteModal, setOpenAddNoteModal] = useState(false);
    const [uniqueid, setUniqueId] = useState(null);
    const [text, setText] = useState('');
    const handleCloseAddNoteModal = () => setOpenAddNoteModal(false);

    const handleAddNote = (uniqueid, text) => {
        setText(text)
        setOpenAddNoteModal(true)
        setUniqueId(uniqueid)
    }
    // dodanie notatki

    useEffect(() => {
        if (socket) {
            socket.on('addNote', () => fetchData())

            socket.on('bridgeleave', () => fetchData())

            return () => {
                socket.off('addNote')
            }
        }
    }, [socket])

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Stack sx={{ alignItems: 'center', width: '100%' }}>
            <h1>Lista billingów</h1>
            {/* Filtrowanie */}
            <Stack direction={isMobile ? 'column' : 'row'} spacing={{ xs: 1, sm: 4 }} width={'100%'}>
                <Stack direction="column" spacing={{ xs: 1, sm: 4 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Data od"
                            onChange={(newValue) => setDateStart(formatDate(newValue.$d))}
                            defaultValue={dayjs(today)}
                            sx={{ width: '100%' }}
                        />
                        <DatePicker
                            label="Data do"
                            onChange={(newValue) => setDateEnd(formatDate(newValue.$d))}
                            defaultValue={dayjs(today)}
                        />
                        <TimeField
                            label="Godzina od"
                            onChange={(newValue) => setTimeStart(extractTime(newValue.$d))}
                            format="HH:mm:ss"
                        />
                        <TimeField
                            label="Godzina do"
                            onChange={(newValue) => setTimeStop(extractTime(newValue.$d))}
                            format="HH:mm:ss"
                        />
                        <FormControl>
                            <InputLabel id="isRecord">Połączenia z nagraniem</InputLabel>
                            <Select
                                labelId='isRecord'
                                value={isRecord}
                                label="Połączenia z nagraniem"
                                onChange={e => setIsRecord(e.target.value)}
                            >
                                <MenuItem value={true}>Tak</MenuItem>
                                <MenuItem value={false}>Nie</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel id="callType">Rodzaj z połączenia</InputLabel>
                            <Select
                                labelId='callType'
                                value={callType}
                                label="Rodzaj z połączenia"
                                onChange={e => setCallType(e.target.value)}
                            >
                                <MenuItem value={'in'}>Przychodzące</MenuItem>
                                <MenuItem value={'out'}>Wychodzące</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel id="user">Użytkownik</InputLabel>
                            <Select
                                labelId='user'
                                value={user}
                                label="Rodzaj z połączenia"
                                onChange={e => setUser(e.target.value)}
                            >
                                {users.map((user, i) => (
                                    <MenuItem key={i} value={user.inphone}>{user.name} {user.lastname}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel id="queue">Kolejka</InputLabel>
                            <Select
                                labelId='queue'
                                value={queue}
                                label="Kolejka"
                                onChange={e => setQueue(e.target.value)}
                            >
                                <MenuItem value={'serwis'}>serwis</MenuItem>
                                <MenuItem value={'serwistest'}>serwistest</MenuItem>
                            </Select>
                        </FormControl>
                    </LocalizationProvider>
                    <Button onClick={fetchData} variant='contained'>Szukaj</Button>
                </Stack>
                {/* Tabela */}
                <TableContainer component={Paper}>
                    <Table sx={{ maxWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align='left' sx={{ fontWeight: 'bold' }}>Rodzaj</TableCell>
                                <TableCell align="left" sx={{ fontWeight: 'bold' }}>Data</TableCell>
                                <TableCell align="left" sx={{ fontWeight: 'bold' }}>Długość rozmowy</TableCell>
                                <TableCell align="left" sx={{ fontWeight: 'bold' }}>Kolejka</TableCell>
                                <TableCell align="left" sx={{ fontWeight: 'bold' }}>Dzwoniący</TableCell>
                                <TableCell align="left" sx={{ fontWeight: 'bold' }}>Odbierający</TableCell>
                                <TableCell align="left" sx={{ fontWeight: 'bold' }}>Rozmówca</TableCell>
                                <TableCell align="left" sx={{ fontWeight: 'bold' }}>Akcje</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data).map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">{transformDisposition(row.disposition, row.src)}</TableCell>
                                    <TableCell align="left" sx={{
                                        minWidth: '120px'
                                    }}>{row.calldate}</TableCell>
                                    <TableCell align="left">{convertSeconds(row.duration)}</TableCell>
                                    <TableCell align="left" sx={{
                                        maxWidth: '100px',
                                        height: '100px',
                                        wordWrap: 'break-word',
                                        whiteSpace: 'normal',
                                    }}>{convertLastData(row.lastdata.slice(0, 25))}</TableCell>
                                    <TableCell align="left">{row.src}</TableCell>
                                    <TableCell align="left">{row.dst}</TableCell>
                                    <TableCell align="left">{row.name} {row.lastname}</TableCell>
                                    <TableCell align="left" sx={{
                                        minWidth: '152px',
                                    }}>
                                        <Stack direction="row">
                                            <IconButton
                                                onClick={() => handleGetRecord(row.uniqueid, row.calldate)}
                                                sx={{ color: 'success.main' }}
                                                disabled={!row.isRecord}
                                            >
                                                <Headphones />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => handleAddNote(row.uniqueid, row.contents)}
                                            >
                                                <NoteAltIcon />
                                            </IconButton>
                                            {row.contents &&
                                                <Tooltip title={row.contents}>
                                                    <IconButton>
                                                        <SpeakerNotes sx={{ color: 'primary.main' }} />
                                                    </IconButton>
                                                </Tooltip>}
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 100 * emptyRows }}>
                                    <TableCell colSpan={8} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={8}
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    slotProps={{
                                        select: {
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        },
                                    }}
                                    labelRowsPerPage="Ilość wierszy na stronie:"
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                {audioSrc &&
                    <Modal
                        open={open}
                        onClose={handleClose}
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                        }}>
                            <AudioPlayer audioSrc={audioSrc} data={modalData} />
                        </Box>
                    </Modal>
                }
                {uniqueid && <NoteAddContainer uniqueid={uniqueid} open={openAddNoteModal} onClose={handleCloseAddNoteModal} text={text} />}
            </Stack>
        </Stack>
    )
}

export default Billingi