import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { getMainUserList, handleSpy, handleSpyWhisper } from '../actions/users';
import { useSocket } from '../hooks/useSocket';
import DialerSipIcon from '@mui/icons-material/DialerSip';
import MicIcon from '@mui/icons-material/Mic';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import IconButton from '@mui/material/IconButton';
import { useAuth } from '../hooks/useAuth';
import { handleForward } from '../actions/users';


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Imię',
    },
    {
        id: 'lastname',
        numeric: false,
        disablePadding: false,
        label: 'Nazwisko',
    },
    {
        id: 'inphone',
        numeric: false,
        disablePadding: false,
        label: 'Nr wewnętrzny',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'Akcje',
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <Typography sx={{ fontWeight: 'bold' }}>{headCell.label}</Typography>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar() {
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
            }}
        >
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Lista uzytkowników
            </Typography>
        </Toolbar>
    );
}

export const generateStatus = (status) => {
    switch (status) {
        case 0:
            return <Typography variant="body2" sx={{ color: 'success.main' }}>Wolny</Typography>
        case 1:
            return <Typography variant="body2" sx={{ color: 'info.main' }}>Rozmawia i moze przyjac</Typography>
        case 2:
            return <Typography variant="body2" sx={{ color: 'info.main' }}>Rozmawia</Typography>
        case 4:
            return <Typography variant="body2" sx={{ color: 'error.dark' }}>Nieosiągalny</Typography>
        case 8:
            return <Typography variant="body2" sx={{ color: 'secondary.main' }}>Dzwoni</Typography>
        case 9:
            return <Typography variant="body2" sx={{ color: 'warning.main' }}>Rozmawia i ktoś dzwoni</Typography>
        case 16:
            return <Typography variant="body2" sx={{ color: 'warning.main' }}>Zatrzymany</Typography>
        case 17:
            return <Typography variant="body2" sx={{ color: 'warning.main' }}>W użyciu i wstrzymane </Typography>
        default:
            // return <Typography variant="body2" sx={{ color: 'error.main' }}>Nieosiągalny</Typography>
            return <Typography variant="body2" sx={{ color: 'error.main' }}>Nieznany</Typography>
    }
}


export default function MainTable() {
    const socket = useSocket();
    const { inphone } = useAuth();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = React.useState([])
    const [inUseUsers, setInUseUsers] = React.useState(null)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, rows],
    );


    const isForwardEnabled = (status) => {
        if (inphone) {
            const isAmIUse = inUseUsers.find(user => user.inphone === inphone)
            if (isAmIUse) {
                return status !== 0
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    const isSpyEnabled = (inp) => {
        if (inUseUsers.length > 0) {
            const usedInp = inUseUsers.filter(user => user.status === 1 || user.status === 2)
            const isUsedUser = usedInp.find(user => user.inphone === inp)
            if (isUsedUser) {
                if (isUsedUser.inphone === inphone) {
                    return true;
                }
                else {
                    return false
                }
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    const isSpyWhisperEnabled = (inp) => {
        if (inUseUsers.length > 0) {
            const usedInp = inUseUsers.filter(user => user.status === 1 || user.status === 2)
            const isUsedUser = usedInp.find(user => user.inphone === inp)
            if (isUsedUser) {
                if (isUsedUser.inphone === inphone) {
                    return true;
                }
                else {
                    return false
                }
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    async function fetchData() {
        const users = await getMainUserList()
        setRows(users)
        const busyUsers = users.filter(user => user.status === 2 || user.status === 1)
        if (busyUsers) {
            setInUseUsers(busyUsers)
        }
        else {
            setInUseUsers(null)
        }
    }

    React.useEffect(() => {
        fetchData()
    }, [])


    React.useEffect(() => {
        if (socket) {
            socket.on('changeStatus', () => {
                fetchData()
            })

            return () => {
                socket.off('changeStatus')
            }
        }
    }, [socket])

    return (
        <Box sx={{ width: '100%' }} id="dupa">
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {visibleRows.length > 0 && visibleRows.map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={index}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="normal"
                                        >
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="left">{row.lastname}</TableCell>
                                        <TableCell align="left">{row.inphone}</TableCell>
                                        <TableCell align="left">{generateStatus(row.status)}</TableCell>
                                        <TableCell align="left">
                                            <IconButton
                                                onClick={() => handleForward(row.inphone)}
                                                disabled={isForwardEnabled(row.status)}
                                            >
                                                <DialerSipIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => handleSpyWhisper(row.inphone)}
                                                disabled={isSpyWhisperEnabled(row.inphone)}
                                            >
                                                <MicIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => handleSpy(row.inphone)}
                                                disabled={isSpyEnabled(row.inphone)}
                                            >
                                                <VolumeUpIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
