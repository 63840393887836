import React, { useEffect, useState } from 'react'
import { Stack, Box, Button, Modal, TextField } from '@mui/material'
import { addNote } from '../actions/billings';

const NoteAddContainer = ({ uniqueid, open, onClose, text }) => {
    const [openState, setOpenState] = useState(open);
    const [contents, setContents] = useState(text)

    const handleClose = () => {
        setOpenState(false);
        if (onClose) onClose();
    };

    const handleAddNote = async () => {
        await addNote(uniqueid, contents)
        onClose();
    }

    useEffect(() => {
        setContents(text)
        setOpenState(open);
    }, [open]);

    return (
        <Modal
            open={openState}
            onClose={handleClose}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <Stack spacing={2} justifyContent="center">
                    <TextField id="note" label="Notatka" variant="outlined" defaultValue={contents} onChange={e => setContents(e.target.value)} />
                    <Stack sx={{ alignItems: 'center' }}>
                        <Button variant='contained' onClick={handleAddNote}>Dodaj</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    )
}

export default NoteAddContainer