import axiosInstance from "../lib/axiosInstance";

export async function sendResetEmail(email) {
    try {
        const key = await axiosInstance.post('/passwordreset', {
            email: email
        })

        return 'success'
    } catch (error) {
        console.error(error)
        throw new Error(error)
    }
}

export async function checkResetKey(key) {
    try {
        const response = await axiosInstance.get(`/passwordreset/${key}`, {
            key: key
        })

        return 'success'
    } catch (error) {
        console.error(error)
        throw new Error(error)
    }
}

export async function resetPassword(key, password) {
    try {
        const response = await axiosInstance.put(`/passwordreset`, {
            key: key,
            password: password
        })

        return 'success'
    } catch (error) {
        console.error(error)
        throw new Error(error)
    }
}