import axiosInstance from "../lib/axiosInstance";

export async function getBillings(data) {
    try {
        const response = axiosInstance.post('/api/billing', data)
        if (!response.status === 200) {
            throw new Error(response.data.message || 'Unauthorized');
        }

        const responseData = (await response).data;
        return responseData;
    } catch (error) {
        console.error(error)
    }
}

export async function getRecord(id) {
    try {
        const response = axiosInstance.get(`/billings/record/${id}`,
            {
                responseType: 'blob'
            }
        )
        if (!response.status === 200) {
            throw new Error(response.data.message || 'Unauthorized');
        }

        const res = await response
        const url = URL.createObjectURL(res.data);
        return url;
    } catch (error) {
        console.error(error)
    }
}

export async function recordStart(channel, uniqueid) {
    try {
        const response = axiosInstance.post('/api/action/recordstart', {
            channel: channel,
            uniqueid: uniqueid
        })
        if (!response.status === 200) {
            throw new Error(response.data.message || 'Unauthorized');
        }

        const responseData = (await response).data;
        return responseData;
    } catch (error) {
        console.error(error)
    }
}

export async function recordStop(channel) {
    try {
        const response = axiosInstance.post('/api/action/recordstop', {
            channel: channel,
        })
        if (!response.status === 200) {
            throw new Error(response.data.message || 'Unauthorized');
        }

        const responseData = (await response).data;
        return responseData;
    } catch (error) {
        console.error(error)
    }
}

export async function addNote(uniqueid, contents) {
    try {
        const response = axiosInstance.post(`/api/action/addnote`,
            {
                uniqueid: uniqueid,
                contents: contents
            }
        )
        if (!response.status === 200) {
            throw new Error(response.data.message || 'Unauthorized');
        }

        const res = await response.data
        return res;
    } catch (error) {
        console.error(error)
    }
}