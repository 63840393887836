import axiosInstance from "../lib/axiosInstance";

export async function getQueueStat() {
    try {
        const response = axiosInstance.get('/api/status/queues')
        if (!response.status === 200) {
            throw new Error(response.data.message || 'Unauthorized');
        }

        const responseData = (await response).data;
        return responseData;
    } catch (error) {
        console.error(error)
    }
}

export async function getQueueMembers() {
    try {
        const response = axiosInstance.get('/api/status/queuemembers')
        if (!response.status === 200) {
            throw new Error(response.data.message || 'Unauthorized');
        }

        const responseData = (await response).data;
        return responseData;
    } catch (error) {
        console.error(error)
    }
}

export async function addMemberToQueue(queue, sip) {
    try {
        const respose = axiosInstance.post('/api/action/queueadd', {
            queue: queue,
            sip: sip
        })
        return await respose
    } catch (error) {
        console.error(error)
    }
}

export async function removeMemberFromQueue(queue, sip) {
    try {
        const respose = axiosInstance.post('/api/action/queueremove', {
            queue: queue,
            sip: sip
        })
        return await respose
    } catch (error) {
        console.error(error)
    }
}