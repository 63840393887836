import React from 'react';

const AudioPlayer = ({ data, audioSrc }) => {
    return (
        <div>
            <h2>Data: {data}</h2>
            {audioSrc && <audio controls src={audioSrc}></audio>}
        </div>
    );
};

export default AudioPlayer;
