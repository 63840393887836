import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import PeopleIcon from '@mui/icons-material/People';
import LayersIcon from '@mui/icons-material/Layers';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import QueueIcon from '@mui/icons-material/Queue';
import MainTable from '../components/MainTable';
import { useSocket } from '../hooks/useSocket';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Kolejki from './kolejki/kolejki';
import Serwis from './kolejki/serwis/serwis';
import SerwisTest from './kolejki/serwistest/serwistest';
import { callNumber, deleteCallback, getCallbacks } from '../actions/callback';
import Billingi from './billingi/billingi';
import AlertContainer from '../components/AlertContainer';
import DeleteIcon from '@mui/icons-material/Delete';
import { ListItem } from '@mui/material';
import MyCall from '../components/MyCall';
import { useIsMobile } from '../hooks/useMobile';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../hooks/useAuth';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);


export default function Root() {
    const {siplogin, logout} = useAuth();
    const socket = useSocket();
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(true);
    const [openList, setOpenList] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const [callbacks, setCallbacks] = React.useState([]);
    const [openModal, setOpenModal] = React.useState(false);
    const [message, setMessage] = React.useState('');

    const fetchData = async () => {
        const res = await getCallbacks();
        setCallbacks(res);
    }

    const handleCall = async (number) => {
        try {
            await callNumber(number);
        } catch (error) {
            setOpenModal(true);
            setMessage('Podłącz się pod centrale');
        }
    }

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleDelete = async (number) => {
        try {
            await deleteCallback(number)
        } catch (error) {
            setOpenModal(true);
            setMessage(JSON.stringify(error));
        }
    }

    React.useEffect(() => {
        if (socket) {
            socket.on('deleteCallback', () => fetchData())

            return () => {
                socket.off('deleteCallback')
            }
        }
    }, [socket])

    React.useEffect(() => {
        if (isMobile) {
            setOpen(false)
        }
        else {
            setOpen(true)
        }
    }, [isMobile])

    React.useEffect(() => {
        fetchData()
    }, [])

    return (
        <Stack>
            <Stack direction="row">
                <Drawer variant="permanent" open={open} sx={{
                    maxHeight: '100vh'
                }}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <Stack direction="row" sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 10px'
                    }}>
                        <Typography>{siplogin}</Typography>
                        <IconButton onClick={() => logout()}>
                            <LogoutIcon />
                        </IconButton>
                    </Stack>
                    <Divider />
                    <List component="nav">
                        <ListItemButton onClick={() => navigate('/')}>
                            <ListItemIcon>
                                <PeopleIcon />
                            </ListItemIcon>
                            <ListItemText primary="Uzytkownicy" />
                        </ListItemButton>
                        <ListItemButton onClick={() => navigate('/kolejki')}>
                            <ListItemIcon>
                                <QueueIcon />
                            </ListItemIcon>
                            <ListItemText primary="Kolejki" />
                        </ListItemButton>
                        <Collapse in={openList} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{
                                    pl: {
                                        xs: 3,
                                        sm: 4
                                    }
                                }} onClick={() => navigate('/kolejki/serwis')}>
                                    <ListItemIcon>
                                        <StarBorderIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="serwis" />
                                </ListItemButton>
                                <ListItemButton sx={{
                                    pl: {
                                        xs: 3,
                                        sm: 4
                                    }
                                }} onClick={() => navigate('/kolejki/serwistest')}>
                                    <ListItemIcon>
                                        <StarBorderIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="serwistest" />
                                </ListItemButton>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={() => navigate('/billingi')}>
                            <ListItemIcon>
                                <LayersIcon />
                            </ListItemIcon>
                            <ListItemText primary="Bilingi" />
                        </ListItemButton>
                        <Divider sx={{ my: 1 }} />
                    </List>
                    {open && <MyCall />}
                    {open && <List sx={{
                        maxHeight: '500px',
                        overflow: 'scroll'
                    }}>
                        <ListSubheader>Callbacks: {callbacks.length}</ListSubheader>
                        {callbacks.sort((a, b) => {
                            const dateA = new Date(a.dialdate);
                            const dateB = new Date(b.dialdate);
                            return dateB - dateA;
                        }).map(callback => (
                            <div key={callback.id}>
                                <ListItem>
                                    <ListItemButton onClick={() => handleCall(callback.calleridnum)}>
                                        <ListItemText
                                            primary={callback.calleridnum}
                                            secondary={callback.dialdate.slice(0, 19)}
                                        />
                                    </ListItemButton>
                                    <IconButton onClick={() => handleDelete(callback.calleridnum)} >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItem>
                                <Divider sx={{ my: 1 }} />
                            </div>
                        ))}
                    </List>}
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                        padding: 0
                    }}
                >
                    <Container sx={{
                        p: {
                            xs: 0,
                            lg: 2
                        }
                    }}>
                        <Routes>
                            <Route path="/" element={<MainTable />} />
                            <Route path="/kolejki" element={<Kolejki />} />
                            <Route path="/kolejki/serwis" element={<Serwis />} />
                            <Route path="/kolejki/serwistest" element={<SerwisTest />} />
                            <Route path="/billingi" element={<Billingi />} />
                        </Routes>
                    </Container>
                    {openModal && <AlertContainer message={message} open={openModal} onClose={handleClose} />}
                </Box>
            </Stack>
        </Stack>
    );
}