import React, { useEffect } from 'react'
import { Stack, Box, Typography, Card, CardContent, IconButton } from '@mui/material'
import { PhoneMissed, PhoneCallback, CallEnd, HourglassFull, AccessAlarms } from '@mui/icons-material'
import { addMemberToQueue, getQueueMembers, getQueueStat, removeMemberFromQueue } from '../../../actions/queue';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { generateStatus } from '../../../components/MainTable';
import { generateDate } from '../../../lib/generateDate';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { getMainUserList } from '../../../actions/users';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useSocket } from '../../../hooks/useSocket';

const SerwisTest = () => {
    const socket = useSocket();
    const [stats, setStats] = React.useState([]);
    const [queueMembers, setQueueMembers] = React.useState([]);
    const [potencialMembers, setPotencialMembers] = React.useState([]);

    async function fetchData() {
        const queues = await getQueueStat()
        const members = await getQueueMembers();
        const sortedMembers = members.filter(member => member.queue === 'serwistest')
        const response = await getMainUserList();
        const potencial = response.filter(user =>
            !members.some(queueMember => queueMember.siplogin === user.siplogin)
        );

        setStats(queues[1])
        setQueueMembers(sortedMembers)
        setPotencialMembers(potencial)
    }

    const handleAdd = async (siplogin) => {
        await addMemberToQueue('serwistest', siplogin)
    }

    const handleRemove = async (siplogin) => {
        await removeMemberFromQueue('serwistest', siplogin)
    }


    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (socket) {
            socket.on('addToQueue', () => fetchData())
            socket.on('delFromQueue', () => fetchData())
        }
    }, [socket])

    return (
        <Stack>
            <Box>
                <Stack sx={{ alignItems: 'center', textAlign: 'center'  }}>
                    <h1>Kolejka: serwistest</h1>
                    <Stack direction={'row'} flexWrap={'wrap'} sx={{ gap: '1rem', justifyContent: 'center' }}>
                        <Card sx={{ minWidth: '189px' }}>
                            <CardContent sx={{ textAlign: 'center' }}>
                                <Typography variant='h5'>Opuszczone</Typography>
                                <PhoneMissed sx={{ fontSize: '3rem', color: 'orange' }} />
                                <Typography variant='h6'>{stats.abandoned}</Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ minWidth: '189px' }}>
                            <CardContent sx={{ textAlign: 'center' }}>
                                <Typography variant='h5'>W kolejce</Typography>
                                <PhoneCallback sx={{ fontSize: '3rem', color: 'lightblue' }} />
                                <Typography variant='h6'>{stats.calls}</Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ minWidth: '189px' }}>
                            <CardContent sx={{ textAlign: 'center' }}>
                                <Typography variant='h5'>Zakończone</Typography>
                                <CallEnd sx={{ fontSize: '3rem', color: 'green' }} />
                                <Typography variant='h6'>{stats.completed}</Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ minWidth: '189px' }}>
                            <CardContent sx={{ textAlign: 'center' }}>
                                <Typography variant='h5'>Oczekiwanie</Typography>
                                <HourglassFull sx={{ fontSize: '3rem', color: 'grey' }} />
                                <Typography variant='h6'>{stats.holdtime}</Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ minWidth: '189px' }}>
                            <CardContent sx={{ textAlign: 'center' }}>
                                <Typography variant='h5'>Czas rozmowy</Typography>
                                <AccessAlarms sx={{ fontSize: '3rem' }} />
                                <Typography variant='h6'>{stats.talktime}s</Typography>
                            </CardContent>
                        </Card>
                    </Stack>
                </Stack>
            </Box>
            <Box>
                <Stack sx={{ alignItems: 'center' }}>
                    <h1>Użytkownicy kolejki: serwistest</h1>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left' sx={{ fontWeight: 'bold' }}>Imię</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>Nazwisko</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>Odebrane rozmowy</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>W trakcie rozmowy</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>Status</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>Ostatnia rozmowa</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>Akcje</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {queueMembers.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="left">{row.name}</TableCell>
                                        <TableCell align="left">{row.lastname}</TableCell>
                                        <TableCell align="left">{row.callstaken}</TableCell>
                                        <TableCell align="left">{row.incall}</TableCell>
                                        <TableCell align="left">{generateStatus(row.status)}</TableCell>
                                        <TableCell align="left">{generateDate(row.lastcall)}</TableCell>
                                        <TableCell align="left">
                                            <IconButton onClick={() => handleRemove(row.siplogin)}>
                                                <PersonRemoveIcon color='error' />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </Box>
            <Box>
                <Stack sx={{ alignItems: 'center' }}>
                    <h1>Użytkownicy mozliwi do dodania do: serwistest</h1>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left' sx={{ fontWeight: 'bold' }}>Imię</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>Nazwisko</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>Login</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>Akcja</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {potencialMembers.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="left">{row.name}</TableCell>
                                        <TableCell align="left">{row.lastname}</TableCell>
                                        <TableCell align="left">{row.siplogin}</TableCell>
                                        <TableCell align="left">
                                            <IconButton onClick={() => handleAdd(row.siplogin)}>
                                                <PersonAddIcon color='primary' />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </Box>
        </Stack>
    )
}

export default SerwisTest