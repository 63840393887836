import axiosInstance from "../lib/axiosInstance"

export async function getMainUserList() {
    try {
        const response = axiosInstance.get('/api/status')
        if (!response.status === 200) {
            throw new Error(response.data.message || 'Unauthorized');
        }

        const responseData = (await response).data;
        return responseData;
    } catch (error) {
        console.error(error)
    }
}

export async function handleForward(exten) {
    try {
        axiosInstance.put('/api/action', {
            exten: exten
        })
    } catch (error) {
        console.error(error)
    }
}

export async function handleSpyWhisper(inphone) {
    try {
        axiosInstance.post('/api/action/spywhisper', {
            number: inphone
        })
    } catch (error) {
        console.error(error)
    }
}

export async function handleSpy(inphone) {
    try {
        axiosInstance.post('/api/action/spy', {
            number: inphone
        })
    } catch (error) {
        console.error(error)
    }
}