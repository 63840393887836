import React, { useEffect, useState } from 'react'
import { Alert, Box, Modal } from '@mui/material'

const AlertContainer = ({ message, open, onClose }) => {
  const [openState, setOpenState] = useState(open);

  useEffect(() => {
    setOpenState(open);
  }, [open]);

  const handleClose = () => {
    setOpenState(false);
    if (onClose) onClose();
  };

  return (
    <Modal
      open={openState}
      onClose={handleClose}
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        p: 4,
      }}>
        <Alert severity="error">{message}</Alert>
      </Box>
    </Modal>
  )
}

export default AlertContainer