import { LockOutlined } from '@mui/icons-material'
import { Avatar, Box, Button, TextField, Typography, Stepper, Step, StepLabel } from '@mui/material'
import React, { useState } from 'react'
import { checkResetKey, resetPassword, sendResetEmail } from '../../actions/passwordReset';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState(null);
    const [key, setKey] = useState(null);
    const [password, setPassword] = useState(null);
    const [error, setError] = useState(null);
    const [step, setStep] = useState(0);

    const steps = ['Podaj adres email', 'Wpisz klucz z wiadomości', 'Podaj nowe hasło'];

    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            setError(null)
            await sendResetEmail(email)
            setStep(1)

        } catch (error) {
            console.error(error)
            setError('Błąd. Skontaktuj się z administratorem.')
        }
    }

    const handleCheckKey = async (e) => {
        try {
            e.preventDefault();
            setError(null)
            await checkResetKey(key)
            setStep(2)
        } catch (error) {
            console.error(error)
            setError('Błąd. Skontaktuj się z administratorem.')
        }
    }

    const handleChangePassword = async (e) => {
        try {
            e.preventDefault();
            setError(null)
            await resetPassword(key, password)
            navigate('/login')
        } catch (error) {
            console.error(error)
            setError('Błąd. Skontaktuj się z administratorem.')
        }
    }

    return (
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlined />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ margin: 5 }}>
                Zresetuj hasło
            </Typography>
            <Stepper activeStep={step}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <Box component="form" onSubmit={
                step === 0 ? handleSubmit
                    :
                    step === 1 ? handleCheckKey
                        :
                        step === 2 && handleChangePassword
            } noValidate sx={{
                mt: 1,
                padding: 4,
                width: {
                    xs: '100%',
                    sm: '100%',
                    md: '50%',
                    lg: '50%',
                    xl: '50%'
                }
            }}>
                {step === 0 ? <TextField
                    margin='normal'
                    required
                    fullWidth
                    id='email'
                    label='Email Address'
                    name='email'
                    autoComplete='email'
                    autoFocus
                    defaultValue={email}
                    onChange={e => setEmail(e.target.value)}
                />
                    :
                    step === 1 && <TextField
                        margin='normal'
                        required
                        fullWidth
                        id='key'
                        label='Klucz'
                        name='key'
                        autoFocus
                        defaultValue={key}
                        onChange={e => setKey(e.target.value)}
                    />}
                {step === 2 && <TextField
                    margin='normal'
                    required
                    fullWidth
                    id='password'
                    label='Nowe hasło'
                    type='password'
                    name='password'
                    autoFocus
                    defaultValue={password}
                    onChange={e => setPassword(e.target.value)}
                />}
                {error && <Typography sx={{ color: 'error.main' }}>{error}</Typography>}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    {step === 0 ?
                        'Wyślij email'
                        :
                        step === 1 ?
                            'Wyślij klucz'
                            :
                            step === 2 &&
                            'Ustaw nowe hasło'
                    }
                </Button>
            </Box>
        </Box>
    )
}

export default ResetPassword