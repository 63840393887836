import React, { useEffect, useState } from 'react'
import { Box, Stack, Typography, IconButton, Modal, TextField, Button } from '@mui/material'
import { useAuth } from '../hooks/useAuth'
import { useSocket } from '../hooks/useSocket'
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { convertSeconds } from '../lib/convertSeconds';
import { recordStart, recordStop } from '../actions/billings';
import { bridgeStatus } from '../actions/bridge';
import { addNote } from '../actions/billings';

const MyCall = () => {
    const socket = useSocket();
    const { inphone } = useAuth();
    const [time, setTime] = useState(0)
    const [isAmIInUse, setIsAmIInUse] = useState(false)
    const [exten, setExten] = useState(null);
    const [channel, setChannel] = useState(null);
    const [uniqueid, setUniqueid] = useState(null);
    const [record, setRecordStart] = useState(null);

    const fetchData = async () => {
        const res = await bridgeStatus();
        if (res.length > 0) {
            setChannel(res[0].channel)
            setExten(res[0].exten)
            setUniqueid(res[0].uniqueid)
            setIsAmIInUse(true)
        }
    }

    const leaveCall = () => {
        setTime(0)
        setIsAmIInUse(false)
        setUniqueid(null)
        setChannel(null)
        setRecordStart(false)
    }

    const handleRecord = async () => {
        await recordStart(channel, uniqueid)
        setRecordStart(true)
    }

    const handleRecordStop = async () => {
        await recordStop(channel)
        setRecordStart(false)
    }

    // dodanie notatki
    const [openAddNoteModal, setOpenAddNoteModal] = useState(false);
    const [text, setText] = useState(localStorage.getItem('centrala_note'));
    const handleCloseAddNoteModal = () => setOpenAddNoteModal(false);

    const handleAddNote = async () => {
        await addNote(uniqueid, text)
        setOpenAddNoteModal(false)
    }

    const handleChange = (e) => {
        setText(e)
        localStorage.setItem('centrala_note', e)
    }
    // dodanie notatki

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (isAmIInUse) {
            const intervalId = setTimeout(() => {
                setTime(prev => prev + 1)
            }, [1000])

            return () => clearInterval(intervalId);
        }
    }, [isAmIInUse, time])

    useEffect(() => {
        if (socket) {
            socket.on('bridgeenter', evt => {
                fetchData();
            })

            socket.on('bridgeleave', evt => {
                leaveCall()
            })
        }
    }, [socket])

    return (
        <Box>
            {isAmIInUse && <Stack sx={{
                backgroundColor: 'success.main',
                color: 'white',
                padding: 2
            }}>
                <Typography variant='body'>Rozmowa z: {exten}</Typography>
                {convertSeconds(time)}
                <Stack direction="row">
                    {!record ?
                        <IconButton onClick={() => handleRecord()}>
                            <KeyboardVoiceIcon sx={{ color: 'white' }} />
                        </IconButton>
                        :
                        <IconButton onClick={() => handleRecordStop()}>
                            <StopCircleIcon sx={{ color: 'white' }} />
                        </IconButton>
                    }
                    <IconButton onClick={() => setOpenAddNoteModal(true)}>
                        <NoteAddIcon sx={{ color: 'white' }} />
                    </IconButton>
                </Stack>
                {uniqueid &&
                    <Modal
                        open={openAddNoteModal}
                        onClose={handleCloseAddNoteModal}
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                        }}>
                            <Stack spacing={2} justifyContent="center">
                                <TextField id="note" label="Notatka" variant="outlined" defaultValue={text} onChange={e => handleChange(e.target.value)} />
                                <Stack sx={{ alignItems: 'center' }}>
                                    <Button variant='contained' onClick={handleAddNote}>Dodaj</Button>
                                </Stack>
                            </Stack>
                        </Box>
                    </Modal>
                }
            </Stack>
            }
        </Box>
    )
}

export default MyCall