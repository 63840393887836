import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { Box, Stack } from '@mui/material';
import { getQueueStat } from '../../actions/queue';

const chartSetting = {
    yAxis: [
        {
            label: 'jednostka (1)',
        },
    ],
    height: 300,
    sx: {
        [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-5px, 0)',
        },
    },
};

export default function Kolejki() {
    const [data, setData] = React.useState([]);

    async function fetchData() {
        const queues = await getQueueStat()
        setData(queues)
    }

    React.useEffect(() => {
        fetchData();
    }, [])

    return (
        <Stack>
            <Box>
                <h2>Statystyki połączeń</h2>
                <BarChart
                    dataset={data}
                    xAxis={[{ scaleType: 'band', dataKey: 'queue' }]}
                    series={[
                        { dataKey: 'abandoned', label: 'Opuszczone' },
                        { dataKey: 'calls', label: 'W kolejce' },
                        { dataKey: 'completed', label: 'Zakończone' },
                    ]}
                    {...chartSetting}
                />
            </Box>
            <Box>
                <h2>Czasy rozmów</h2>
                <BarChart
                    dataset={data}
                    xAxis={[{ scaleType: 'band', dataKey: 'queue' }]}
                    series={[
                        { dataKey: 'holdtime', label: 'Oczekiwanie' },
                        { dataKey: 'talktime', label: 'Czasy rozmów' },
                    ]}
                    {...chartSetting}
                />
            </Box>
        </Stack>
    );
}