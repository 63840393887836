import { useState, useEffect } from 'react';

// Hook do sprawdzania, czy urządzenie ma widok mobilny czy desktopowy
export function useIsMobile(breakpoint = 1170) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoint);

    useEffect(() => {
        // Funkcja do aktualizacji stanu na podstawie szerokości okna
        function handleResize() {
            setIsMobile(window.innerWidth < breakpoint);
        }

        // Nasłuchiwania na zmianę rozmiaru okna
        window.addEventListener('resize', handleResize);

        // Czyszczenie nasłuchiwania przy odmontowywaniu komponentu
        return () => window.removeEventListener('resize', handleResize);
    }, [breakpoint]); // Efekt zależy od breakpointu

    return isMobile;
}