import './App.css';
import { AuthProvider } from './hooks/useAuth';
import { Routes, Route } from 'react-router-dom';
import Login from './routes/login/login';
import { ProtectedRoute } from './components/ProtectedRoute';
import Root from './routes/root';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Container } from '@mui/material';
import { SocketProvider } from './hooks/useSocket';
import ResetPassword from './routes/reset-password/reset-password';

const defaultTheme = createTheme();
function App() {
  return (
    <AuthProvider>
      <SocketProvider>
        <ThemeProvider theme={defaultTheme}>
          <Container component="main" maxWidth="2xl" style={{
            padding: 0,
          }}>
            <CssBaseline />
            <Routes>
              <Route index path="*" element={
                <ProtectedRoute>
                  <Root />
                </ProtectedRoute>
              } />
              <Route path="/login" element={<Login />} />
              <Route path="/reset-password" element={<ResetPassword />} />
            </Routes>
          </Container>
        </ThemeProvider>
      </SocketProvider>
    </AuthProvider>
  );
}

export default App;
