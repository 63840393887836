import axiosInstance from "../lib/axiosInstance";

export async function getCallbacks() {
    try {
        const response = axiosInstance.get('/api/status/callback')
        if (!response.status === 200) {
            throw new Error(response.data.message || 'Unauthorized');
        }

        const responseData = (await response).data;
        return responseData;
    } catch (error) {
        console.error(error)
    }
}

export async function callNumber(number) {
    try {
        const response = axiosInstance.post('/api/action/call', {
            number: number
        })
        if (!response.status === 200) {
            throw new Error(response.data.message || 'Unauthorized');
        }

        const responseData = (await response).data;
        return responseData;
    } catch (error) {
        console.error(error)
        throw new Error(error)
    }
}

export async function deleteCallback(number) {
    try {
        const response = axiosInstance.post('/api/callback/delete', {
            number: number
        })
        if (!response.status === 200) {
            throw new Error(response.data.message || 'Unauthorized');
        }

        const responseData = (await response).data;
        return responseData;
    } catch (error) {
        console.error(error)
        throw new Error(error)
    }
}