import { createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import axiosInstance from "../lib/axiosInstance";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null)
    const [inphone, setInphone] = useLocalStorage("inphone", null);
    const [siplogin, setSipLogin] = useLocalStorage("siplogin", null);
    const navigate = useNavigate();

    const login = async (data) => {
        try {
            const response = axiosInstance.post('https://devapicentrala.itprocare.pl/session', data)

            if (!response.status === 200) {
                throw new Error(response.data.message || 'Login failed');
            }

            const responseData = (await response).data;

            if (localStorage.getItem('centrala_remember')) {
                localStorage.setItem('user', JSON.stringify(responseData.token))
            }
            setUser(responseData.token)
            setInphone(responseData.inphone)
            setSipLogin(responseData.siplogin)
            navigate('/')
        } catch (error) {
            console.error(error)
        }
    }

    const logout = () => {
        setUser(null)
        setInphone(null)
        setSipLogin(null)
        localStorage.removeItem('user')
        localStorage.removeItem('inphone')
        localStorage.removeItem('siplogin')
        localStorage.removeItem('centrala_remember')
        navigate('/', { replace: true })
    }

    const value = useMemo(
        () => ({
            user,
            inphone,
            siplogin,
            login,
            logout
        }),
        [user, inphone]
    )

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext)
}