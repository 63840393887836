import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client'
import { useAuth } from './useAuth';

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const { user } = useAuth();
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (user) {
            const newSocket = io('https://devapicentrala.itprocare.pl', {
                autoConnect: true,
                transports: ['websocket'],
                withCredentials: true,
                auth: {
                    token: user
                }
            });
            setSocket(newSocket)

            return () => {
                newSocket.disconnect()
            }
        }
    }, [user])

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    )
}

export const useSocket = () => {
    return useContext(SocketContext)
}