export function formatDate(elem) {
    const date = new Date(elem);

    // Uzyskujemy poszczególne części daty
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Dodajemy 1, ponieważ miesiące w JavaScript są indeksowane od 0
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}