import axiosInstance from "../lib/axiosInstance";

export async function bridgeStatus() {
    try {
        const response = axiosInstance.get('/api/status/bridge')
        if (!response.status === 200) {
            throw new Error(response.data.message || 'Unauthorized');
        }

        const responseData = (await response).data;
        return responseData;
    } catch (error) {
        console.error(error)
    }
}